<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>数据分析</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>
        <el-select v-model="listQuery.adId" placeholder="平台" clearable  @change="qadIdChange" >
            <el-option
                v-for="item in qadOptions"
                :key="item.id"
                :label="item.adName"
                :value="item.id">
            </el-option>
        </el-select>

        <el-select v-model="listQuery.name" value-key="id" clearable   placeholder="app" style="margin-left: 30px" @change="qappIdChange" >
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item">
            </el-option>
        </el-select>

        <el-date-picker
        style="margin-left:30px"
        v-model="listQuery.dateYMd"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="(value) => datechange(value)"
        >
        </el-date-picker>

        <el-select v-model="listQuery.name" value-key="id" clearable   placeholder="数据类型" style="margin-left: 30px" @change="qappIdChange" >
            <el-option
                v-for="item in datasType"
                :key="item.id"
                :label="item.name"
                :value="item">
            </el-option>
        </el-select>

        <el-button type="primary" style="margin-left:30px">查询</el-button>

        <!-- <el-date-picker
            style="margin-left:30px"
            v-model="listQuery.dateYMd"
            value-format="yyyy-MM-dd"
            type="date"
            @change="(value) => datechange(value)"
            placeholder="选择日期">
        </el-date-picker> -->
    </el-row>

    <br />
    <div style="background: #fff ">
        <div id="echarts_box" style="width: 100%;height:75vh;"></div>
        <div style="height: 30px;" />
    </div>


  </div>
</template>

<script>
import { saveApi,delApi, listApi2 } from '@/api/appProfit'
import {listAllApi} from "@/api/apps";
import { datefomate } from '../utils/formatter';
import { listAllByAppId, publishAd, createTabs } from '@/api/ads'
import echarts from 'echarts'

export default {
  name: "appProfit",
  components: {  },
  inject: ['load','closeLoad'],
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        dateYMd:'',
        appId:'',
        adId:''
      },
      echartsTimeDate:[],
      switchValue1: false,
      dialogFormVisible: false,
      dialogFormVisible1:false,
      dialogStatus: '',
      dialogStatus1:'',
      pickerOptions: {
        disabledDate(time) {
            return time.getTime() > Date.now() || time.getTime() < Date.now() - 30 * 24 * 60 * 60 * 1000;
        }
      },
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        bgId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        sex: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '不能为空', trigger: 'blur' }],
        wechat: [{ required: true, message: '不能为空', trigger: 'blur' }],
        qq: [{ required: true, message: '不能为空', trigger: 'blur' }],
        email: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },

      temp: {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',

      },
      fomrs:{
        appId: '',
        dateYMd: '',
        adId:''
      },
      loadings:false,
      appName:'',
      tableData: [],
      appOptions:[],
      datasType:[
        {id:1,name:'收益',value:'1'},
        {id:2,name:'日活',value:'2'},
        {id:3,name:'新增',value:'3'},
        {id:4,name:'次日留存',value:'4'}
      ],//数据类型
      adOptions:[],
      companyOptions:[],
      qadOptions:[],
      testArr1: [],
      testArr2: [],
      testArr3: [],
      testPosition1: 0,
      testPosition2: 0,
      testPosition3:0,
      sexOptions:[{
        id: 1,
        label: '男'
      },{
        id: 2,
        label: '女'
      }],
    }
  },
  created() {
    this.getList();
    this.getInitOptions();
    if(this.listQuery.dateYMd == '') {
        this.defaultQueryTime()
    }
  },
  mounted() {
      this.echartsData()
  },
  methods:{
    // setAppId(data) {
    //   console.log(data)
    //   this.fomrs.appId = data.id
    // },
    defaultQueryTime() {
        let cur = new Date().getTime()
        let dataArr = []
        for (var i = 6; i >= 0; i--) {
            dataArr.push(datefomate(cur - i * 24 * 60 * 60 * 1000))
        }
        this.echartsTimeDate = dataArr
        this.listQuery.dateYMd = [dataArr[0],dataArr[dataArr.length - 1]]
    },
    selQueryTime(time) {
        let star = new Date(time[0]).getTime()
        let end = new Date(time[1]).getTime()
        let oneDay = 24 * 60 * 60 * 1000
        let ftTime = (end - star) / oneDay
        let dataArr = []
        console.log(ftTime)
        for(var i = 0; i <= ftTime; i++) {
            dataArr.push(datefomate(star + i * 24 * 60 * 60 * 1000))
        }
        this.echartsTimeDate = dataArr
    },
    echartsData() {
        let myChart = echarts.init(document.getElementById('echarts_box'))
        var option = {
            title: {
                // text: 'ECharts'
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                    label: {
                        backgroundColor: "#76baf1"
                    }
                }
            },
            legend: {
                // data:['销量'],
                // orient: 'vertical',
                // right:200,
                // y: 'center',
                // itemGap:30,
                // itemWidth:8,
                // padding:10,
                // textStyle:{
                //     fontSize: 12
                // },
                // align:'left',
                // data: [
                //     {
                //         name:'货币',
                //         icon:'circle'
                //     },
                //     {
                //         name:'股票',
                //         icon:'circle'
                //     },{
                //         name:'债券',
                //         icon:'circle'
                //     }
                // ]
                
            },
            xAxis: {
                boundaryGap: false,
                data: this.echartsTimeDate,
                axisLabel: {  
                    interval:0,  
                    rotate:45  
                }
            },
            yAxis: {
                name:'单位：(万元)',
                nameTextStyle: {
                    color:'rgba(0,0,0,.5)',
                    padding: [20, 0, 10, 10]
                }
                // axisLabel: {
                //     show: true,
                //     interval: 'auto',
                //     formatter: '{value}(%)'
                // }
            },
            series: [{
                name: '',
                type: 'line',
                areaStyle: {color: 'rgba(0,0,0,.2)'},
                itemStyle : {
                    normal : {
                        color:'#409EFF',
                        lineStyle:{
                            color:'#409EFF',
                        }
                    }
                },
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: false
                    }
                },
                data: [5, 20, 36, 10, 10, 40,20]
            }]
        };
        myChart.setOption(option)
    },
    changeMoney() {
      if(this.temp.actualRatio >= 1000) {
        this.temp.actualRatio = 1000
      }
      if(this.temp.actualRatio <= 0) {
        this.temp.actualRatio = 10
      }
      this.temp.showRevenue = (this.temp.actualRevenue * (this.temp.actualRatio / 1000)).toFixed(2)
    },
    changeExposure() {
      this.temp.ecpm = ((this.temp.showRevenue / this.temp.exposureNumber ) * 1000).toFixed(2)
    },
    // appIdChange(val){
    //   console.info("广告位")
    //   console.info(val)
    //   //广告位
    //   this.temp.adId='';
    //   console.log(val.id)
    //   listAllByAppId({appId: val.id}).then(response => {
    //     this.adOptions = response.body
    //   })
    // },
    qappIdChange(val){
      console.info(val.id)
      //广告位
      this.listQuery.adId='';

      this.listQuery.current = 1;
      this.listQuery.appId = val.id
      this.appName = val.name
      
      listAllByAppId({appId: val.id}).then(response => {
        console.log(response)
        this.qadOptions = response.body
        this.getList();
      })

    },
    qadIdChange(val){
      console.info(val)
      this.listQuery.current = 1;
      this.getList();

    },
    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
        this.appOptions = response.body.records
      })
    },
    change(data,val) {
       let statusVal = data.cp
       console.log(this.tableData[val].publish)
       this.tableData[val].cp = statusVal ? false : true
       let confVal = statusVal ? 0 : 1
       console.log(this.tableData[val].publish)
       this.$confirm('是否确认发布?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.load()
        this.loadings = true
        this.tableData[val].cp = confVal ? false : true
        this.tableData[val].publish = confVal ? 0 : 1
        let para = {
          appId:data.appId,
          dateYMd:data.dateYmd
        }
        publishAd(para).then(response => {
          if(response.code === 1) {
              this.getList()
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: ''
        // });
      });
      // console.log(data)
    },
    rowspan(spanArr, position, spanName,vals) {
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          spanArr.push(1);
          position = 0;
        } else {
          if (
            this.tableData[index][spanName] ===
            this.tableData[index - 1][spanName] && 
            this.tableData[index][vals] === 
            this.tableData[index - 1][vals]
          ) {
            spanArr[position] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            position = index;
          }
        }
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        const _row = this.testArr1[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 3) {
        const _row = this.testArr2[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 13) {
        const _row = this.testArr3[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    getList() {
      this.loadings = true
      listApi2(this.listQuery).then(response => {
        // this.closeLoad()
        this.loadings = false
        let datas = response.body.records
        datas.forEach((item)=>{
          item.cp = item.publish ? true : false
        })
        this.tableData = datas
        this.total = response.body.total
        console.log(this.tableData)
        this.testArr1 = []
        this.testArr2 = []
        this.testArr3 = []
        this.testPosition1 = 0
        this.testPosition2 = 0
        this.testPosition3 = 0
        this.rowspan(this.testArr1, this.testPosition1, "appName","dateYmd");
        this.rowspan(this.testArr2, this.testPosition2, "appName","dateYmd");
        this.rowspan(this.testArr3, this.testPosition3, "appName","dateYmd");
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    createDataTab() {
      console.log(this.fomrs)
      let para = {
        adId:this.fomrs.adId ? this.fomrs.adId.id : '',
        appId:this.fomrs.appId.id,
        dateYMd:this.fomrs.dateYMd
      }
      if(!para.appId || !para.dateYMd) return
       createTabs(para).then(() => {
        //this.tableData.unshift(this.temp)
        this.getList();
        this.dialogFormVisible1 = false
        this.$notify({
          title: 'Success', 
          message: '录入成功',
          type: 'success',
          duration: 2000
        })
      })
      // this.$refs['dataForms'].validate((valid) => {
      //   if (valid) {
      //     createTabs(para).then(() => {
      //       //this.tableData.unshift(this.temp)
      //       this.getList();
      //       this.dialogFormVisible1 = false
      //       this.$notify({
      //         title: 'Success',
      //         message: '录入成功',
      //         type: 'success',
      //         duration: 2000
      //       })
      //     })
      //   }
      // })
    },
    datechange(){
    if(this.listQuery.dateYMd == '') {
        this.defaultQueryTime()
    } else {
        this.selQueryTime(this.listQuery.dateYMd)
        this.echartsData()
    }
    //   console.log(this.listQuery.dateYMd);
      // this.dateYMd = this.listQuery.dateYMd
    //   this.listQuery.current = 1;
    //   this.getList();

    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log(this.temp);
          saveApi(this.temp).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetFomes() {
      this.fomrs = {
        appId: '',
        dateYMd: '',
        fomrs:''
      }
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',
      }
    },

    handleUpdate(index, row) {
      console.log(row)
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      if(!row.actualRatio) {
        this.temp.actualRatio = '1000'
      }
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          saveApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.getList();
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}

</script>

<style scoped>


</style>
