export function forMatter(fmt,date) { //author: meizz 
    let ret;
    let opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    };
    for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
    fmt=fmt.replace(ret[1],(ret[1].length==1)?(opt[k]):(opt[k].padStart(ret[1].length,"0")))
    }
    }
    return fmt;
}


export function datefomate(value) {
    var y=new Date(value).getFullYear();
    var m=new Date(value).getMonth()+1;
    var d=new Date(value).getDate();
    return y + "-" + ft(m) +"-" + ft(d);  
}

function ft(data) {
    return data < 10 ? '0' + data : data
}